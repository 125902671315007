import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "eu-statute-consolidated",
  languages: [
    "fi",
    "sv",
  ],
  title: "label.euStatuteConsolidated",
  description: "description.euStatuteConsolidated",
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
    sidebar: {
      jumpToToc: {
        enabled: true,
        article: {
          enabled: true,
          required: true,
        },
      },
    },
  }),
}
