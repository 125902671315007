<template lang="html">
  <router-view
    @login="login"
    @login-external="loginExternal"
  />
</template>

<script>
export default {
  name: "GenericWrapper",
  methods: {
    login(data) {
      this.$emit("login", data)
    },
    loginExternal(data) {
      this.$emit("login-external", data)
    },
  },
}
</script>
