import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "statute-consolidated",
  languages: [
    "fi",
    "sv",
  ],
  title: "label.statuteConsolidated",
  description: "description.statuteConsolidated",
  configuration: merge({}, defaultConfig, {
    actions: {
      languages: true,
      pdf: false,
      referenceCard: false,
      edilexReferenceCard: true,
    },
    listing: {
      caption: true,
      title: true,
      type: true,
    },
    sidebar: {
      // Enable jump to functionality for material
      jumpToDocument: { enabled: true },
      jumpToToc: {
        enabled: true,
        chapter: {
          enabled: true,
          required: false,
        },
        section: {
          enabled: true,
          required: false,
        },
      },
      subsection: { subitems: { translate: false } },
      updateInfo: true,
    },
  }),
}
