import axios from "./axios-instance"
import store from "@/store"

export default {
  /**
   * @param Object Payload containing material information
   */
  getDirectory: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.materialType !== "string"
      || typeof payload.language !== "string"
    ) {
      reject(new Error("[directory.getDirectory] Payload incorrect"))
    }
    axios(store)({
      url: `/directory/${payload.materialType}/${payload.language}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
  getHeadings: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.level !== "number"
      || isNaN(payload.level)
      || parseInt(payload.level) <= 0
    ) {
      reject(new Error("[directory.getHeadings] Payload incorrect"))
    }
    axios(store)({
      url: `/directory/headings/${payload.level}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  }),
}
