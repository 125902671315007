<script>
import { mapState } from "vuex"
import languages from "@common/config/languages"
import USERTYPE from "@common/config/user-types"
import { routes } from "@/router"
import editaConfig from "@/edita.config.js"

export default {
  computed: {
    ...mapState({
      availableLanguages: "languages",
      uiLanguage: "language",
    }),
    applicationLanguages() {
      return languages.filter(language => this.availableLanguages.indexOf(language.code) >= 0)
    },
    extraNav() {
      return editaConfig.PageHeader.extraNav
    },
    hasUserZone() {
      return this.userType === USERTYPE.ACCOUNT
        || this.userType === USERTYPE.EXTERNAL
        || this.userType === USERTYPE.IP
    },
    navItems() {
      return [
        {
          name: "home",
          meta: { title: "label.home" },
        },
        ...routes.filter(route => !route.meta || (route.meta && !route.meta.hidden)),
      ]
    },
    navCurrentRoute() {
      return this.$route.name
    },
    navCurrentRouteParams() {
      return this.$route.params
    },
    optionsPageFooter() {
      return editaConfig.PageFooter
    },
    serviceName() {
      return editaConfig.serviceCode
    },
    showFooter() {
      return this.loggedIn
        || !this.optionsPageFooter.requireAuthentication
        || !editaConfig.auth.useAuthentication
    },
    showHeader() {
      return this.loggedIn
        || !editaConfig.PageHeader.requireAuthentication
        || !editaConfig.auth.useAuthentication
    },
    showNav() {
      return this.loggedIn
        || !editaConfig.auth.useAuthentication
        || !editaConfig.auth.requireAuthentication
    },
  },
  watch: {
    "$route.hash": function(newHash, oldHash) {
      // Scroll to hash when it changes. For example document view's toc links
      if (newHash !== oldHash) {
        this.anchorHashCheck(newHash)
      }
    },
  },
  methods: {
    anchorHashCheck(hash) {
      if (hash.substring(1).length > 0) {
        const element = window.document.getElementById(hash.substring(1))

        if (element) {
          element.scrollIntoView()
        }
      }
    },
    materialJumpTo({ materialType, query }) {
      this.$router.push({
        name: this.$router.findMaterialJumpToRoute(materialType),
        query: query,
      })
    },
  },
}
</script>
