// All actions used by store
export default {
  EDILEX_AUTHENTICATE: "edilex-authenticate",
  EDILEX_SIGNOUT: "edilex-signout",
  FETCH_GLOBALLY_REQUIRED_INFORMATION: "fetch-globally-required-information",
  GET_DIRECTORY_HEADINGS: "get-directory-headings",
  GET_DIRECTORY: "get-directory",
  GET_DOCUMENT_BY_ID: "get-document-by-id",
  GET_DOCUMENT_CNAA: "get-document-common-names-and-abbreviations",
  GET_KEYWORD_DOCUMENTS: "get-keyword-documents",
  GET_KEYWORD_LETTERS: "get-keyword-letters",
  GET_KEYWORDS: "get-keywords",
  GET_SUBJECTS: "get-subjects",
  GET_UPDATED_INFO: "get-updated-info",
  GET_YEARS: "get-years",
  GO_LOGIN_REDIRECT: "go-login-redirect",
  JUMP_TO_ANCHOR: "jump-to-anchor",
  MATERIAL_JUMP_TO: "material-jump-to",
  SEARCH_DOCUMENTS: "search-documents",
  SEARCH_KEYWORDS: "search-keywords",
  SEND_FEEDBACK: "send-feedback",
  SET_DIRECTORY_HEADINGS: "set-directory-headings",
  SET_DIRECTORY: "set-directory",
  SET_DOCUMENT_YEARS: "set-document-years",
  SET_KEYWORD_LETTERS: "set-keyword-letters",
  SET_LANGUAGE: "set-language",
  SET_LOGIN_REDIRECT: "set-login-redirect",
  SET_PAGE_TITLE: "set-page-title",
  SET_SUBJECTS: "set-subjects",
  SET_UPDATED_INFO: "set-updated-info",
  UPDATE_SIDEBAR_HEADINGS: "update-sidebar-headings",
  WEBSHOP_AUTHENTICATE: "webshop-authenticate",
  WEBSHOP_SIGNOUT: "webshop-signout",
  WHOAMI: "whoami",
}
