import { render, staticRenderFns } from "./DocumentPageWrapper.vue?vue&type=template&id=c4ef3bd0&scoped=true&lang=html&"
import script from "./DocumentPageWrapper.vue?vue&type=script&lang=js&"
export * from "./DocumentPageWrapper.vue?vue&type=script&lang=js&"
import style0 from "./DocumentPageWrapper.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DocumentPageWrapper.vue?vue&type=style&index=1&id=c4ef3bd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../modules/rajalex/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4ef3bd0",
  null
  
)

export default component.exports