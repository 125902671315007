<template lang="html">
  <ContentContainer variant="section">
    <SidebarMaterial
      :active="$route.name"
      :anchor="$route.hash"
      :advanced-search-route="advancedSearchRoute"
      :has-subsection="configuration.sidebar.subsection.enabled"
      :has-search="configuration.sidebar.search"
      :subsubsection="configuration.sidebar.subsection.subitems"
      :has-update-info="configuration.sidebar.updateInfo"
      :items="section.children"
      :material-type="materialType"
      :options-material-jump-to="configuration.sidebar.jumpToDocument"
      :section="section"
      :ui-language="uiLanguage"
      :update-info="updateInfo"
      @material-jump-to="storeMaterialJumpTo"
      @search-global="searchGlobal"
    />
    <MainContainer>
      <Heading level="h1">
        {{ $t($route.meta.title) }}
      </Heading>

      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="!loading && !error">
        <div class="commonnames-abbreviations-desc">
          {{ $t(`description.abbreviations.${materialType}`) }}
        </div>
        <dl class="commonnames-abbreviations-list">
          <div
            v-for="item in items"
            :key="item.label"
          >
            <dt>
              <abbr>
                {{ item.label }}
              </abbr>
            </dt>
            <dd>
              {{ item.document.DocumentTitle }}
              <BaseLink
                :to="{
                  name: item.document.routes.view,
                  params: {
                    language: item.document.language,
                    documentKey: item.document.documentKey,
                  }
                }"
              >
                {{ item.document.DocumentCaption }}
              </BaseLink>
            </dd>
          </div>
        </dl>
      </MainContentContainer>
      <Backtotop
        v-if="showBacktotop"
        @click="goBacktotop"
      />
    </MainContainer>
  </ContentContainer>
</template>

<script lang="javascript">
import { sortBy } from "lodash"
import { mapActions } from "vuex"
import storeTypes from "@common/config/store-types"
import { handleError } from "./utils.js"
import Backtotop from "./mixins/Backtotop.vue"
import Sidebar from "./mixins/Sidebar.vue"

export default {
  name: "CommonNamesAndAbbreviationsWrapper",
  mixins: [
    Backtotop,
    Sidebar,
  ],
  props: {
    /**
     * Material type used for listing
     */
    materialType: {
      type: Array,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
    /**
     * Current section configuration
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      error: undefined,
      items: [],
      loading: true,
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    ...mapActions("document", {
      storeGetCommonNamesAndAbbreviations: storeTypes.GET_DOCUMENT_CNAA,
      storeMaterialJumpTo: storeTypes.MATERIAL_JUMP_TO,
    }),
    getItems() {
      this.error = undefined
      this.loading = true
      this.storeGetCommonNamesAndAbbreviations({
        language: this.uiLanguage,
        materialType: this.materialType,
      })
        .then((result) => {
          this.$set(this, "items", sortBy(result, "label"))
          this.loading = false
        })
        .catch((error) => {
          this.loading = false

          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.commonnames-abbreviations-list {
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
  margin: 0;
  div {
    background: white;
    flex-direction: column;
    padding: 12px;
  }
  dt {
    font-size: .8125rem;
    font-weight: 700;
    color: #656c74;
  }
  a {
    color: #003f75;
    &:visited {
      color: #4d8cc1;
    }
  }
}
</style>
