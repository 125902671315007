import VueRouter from "vue-router"
import languageConfig from "@common/config/languages"
import STORETYPE from "@common/config/store-types"

// Wrappers for DS templates to use as router components
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"
import FeedbackWrapper from "@wrappers/FeedbackWrapper.vue"
import FrontPageWrapper from "@wrappers/FrontPageWrapper.vue"
import GenericWrapper from "@wrappers/GenericWrapper.vue"
import KeywordListWrapper from "@wrappers/KeywordListWrapper.vue"
import LoginWrapper from "@wrappers/LoginWrapper.vue"
import NotFoundWrapper from "@wrappers/NotFoundWrapper.vue"
import SearchPageWrapper from "@wrappers/SearchPageWrapper.vue"
import SubjectListWrapper from "@wrappers/SubjectListWrapper.vue"
import {
  findParentRoute,
  findRoute,
  findRouteEndsWidth,
  getPageTitle,
} from "./utils"
import { ALL as MATERIALS, MODULE_OTHER_DOCUMENT } from "@/config/material-type"
// TODO: Metatags to routes
// See: https://alligator.io/vuejs/vue-router-modify-head/ for some tips (should not use ready package)

function createRouter(options, moduleRoutes) {
  if (typeof options.languages !== "object") {
    throw "[createRouter] Application 'languages' not defined or not an array"
  }
  if (typeof options.auth !== "object") {
    throw "[createRouter] Application 'auth' settings not defined or not an object"
  }
  if (typeof moduleRoutes !== "object") {
    throw "[createRouter] Application 'moduleRoutes' not defined or not an object"
  }

  // Get only used languages from configuration based on module options
  const languages = Object.keys(options.languages)
    .filter(key => languageConfig.filter(lang => lang.code === key))
    .reduce((languageList, key) => {
      languageList.push(languageConfig[key])

      return languageList
    }, [])

  const authRoutes = options.auth.useAuthentication
    ? [
      {
        path: "auth",
        component: GenericWrapper,
        children: [
          {
            path: "login",
            name: "login",
            component: LoginWrapper,
            meta: {
              title: "label.login",
              isPublic: true,
            },
          },
        ],
      },
    ]
    : []

  // TODO: Should this be separated to different file?
  const routes = [
    { // Force redirect to the first language
      path: "/",
      redirect: `/${options.languages[0]}`,
      // TODO: Do not directly insert redirect?
      // Maybe this should be included below and add routeguard to use language
      // when not defined.
    },
    {
      path: "/:language",
      component: GenericWrapper,
      meta: {
        hidden: false, // Default: false
        title: "service.name",
      },
      children: [
        {
          path: "",
          name: "home",
          component: FrontPageWrapper,
          meta: { isPublic: !options.auth.requireAuthentication },
        },
        {
          // Search is only allowed when authentication is not required or guest
          // users are allowed on the site.
          path: "haku",
          name: "search",
          component: SearchPageWrapper,
          meta: { isPublic: !options.auth.requireAuthentication },
        },
        ...options.routes.feedback ? [
          {
            path: "palaute",
            component: FeedbackWrapper,
            name: "feedback",
            meta: {
              isPublic: !options.auth.requireAuthentication,
              title: "label.feedback",
              description: "description.feedback",
            },
          },
        ] : [],
        ...options.routes.keywords ? [
          {
            path: "asiasanat/:text(.+)?",
            component: KeywordListWrapper,
            name: "keywords",
            props: {
              // This is global keywords list; empty material type means all materials
              materialType: [],
            },
            meta: {
              isPublic: !options.auth.requireAuthentication,
              title: "label.keywords",
            },
          },
        ] : [],
        ...options.routes.subjects.enabled ? [
          {
            path: "aihealueet/:text(.+)",
            component: SubjectListWrapper,
            name: "subjects",
            meta: {
              isPublic: !options.auth.requireAuthentication,
              title: "label.subjects",
            },
          },
        ] : [],
        // Add more routes from options
        // ... appends to array
        ...authRoutes,
        ...moduleRoutes,
        // Send a query to Api to get static pages and make routes from them?
        // Should this be cached to client so not every page load asks for
        // static pages? Usually they do not change often as long as customers'
        // material (like currently some custom content in CA3) does not use
        // static pages (inserted as regular documents to ES).
        {
          path: ":documentKey",
          name: "module-other-document.documentView",
          component: DocumentPageWrapper,
          props: {
            materialType: ["module-other-document"],
            configuration: MODULE_OTHER_DOCUMENT.configuration,
            section: { children: [] },
          },
          meta: { isPublic: !options.auth.requireAuthentication },
        },
        {
          path: "*",
          name: "not-found",
          component: NotFoundWrapper,
          meta: { isPublic: true },
        },
      ],
    },
  ]

  const router = new VueRouter({
    mode: "history",
    // eslint-disable-next-line no-undef
    base: __dirname,
    routes,
    languages,
    scrollBehavior: function(to, from, savedPosition) {
      // NOTE: Not sure if this is helpful. Does not work on dynamic pages
      // like archive since when you move to previous page (back button)
      // the content is loaded again so the location no longer matches so you c/annot scroll
      // TODO: Should there be event emitted and _then_ scrolled? Would this
      // interfere with anchor scroll event (JUMP_TO_ANCHOR)?
      if (savedPosition) {
        return savedPosition
      } else if (to.path !== from.path) {
        // Move page to top when loading new document
        document.querySelector("#app").scrollTo(0, 0)
      }
    },
    linkActiveClass: "eds-BaseLink-active",
    linkExactActiveClass: "eds-BaseLink-exact-active",
  })

  // eslint-disable-next-line no-unused-vars
  router.afterEach((to, from) => {
    const store = router.app.$store

    store.dispatch(STORETYPE.SET_PAGE_TITLE) // Update page's title
  })

  // Set dictionary language from router
  router.beforeEach((to, from, next) => {
    // TODO: Some of this should be moved to utils.js
    const store = router.app.$store

    const parentTo = to.matched.find(match => match.name === to.name).parent

    // use the language from the routing parabm or default language
    let language = to.params.language
    const documentLanguage = to.params.documentLanguage
    const materialType = parentTo && parentTo.props.default ? parentTo.props.default.materialType : null

    if (!language || languages.filter(lang => lang.code === language).length === 0) {
      language = languages[0].code

      // Redirect to default (first) language
      return next({ path: `/${language}${to.fullPath}` })
    } else if (to.meta.validateDocumentLanguage && documentLanguage
      && materialType && MATERIALS[materialType].languages.indexOf(documentLanguage) === -1
    ) {
      // User tried to go to document language version that is not allowed,
      // redirect user to not found.
      return next({
        name: "not-found",
        params: { language },
      })
    } else {
      store.dispatch(STORETYPE.SET_LANGUAGE, { language })

      return next()
    }
  })

  // Check if authentication is required for route
  router.beforeEach((to, from, next) => {
    const store = router.app.$store
    const language = to.params.language
    const routeRequiresAuthentication = to.meta
      && typeof to.meta.isPublic !== "undefined"
      && !to.meta.isPublic
    const userIsNotLoggedIn = !store.state.auth.loggedIn
      && store.state.auth.whoamiDone

    // Check that user is not logged in and we have already done whoami call
    // This way login redirect is not triggered on first page load but when
    // whoami request is made; this allows IP authentication to work properly
    // without redirecting user to login page.
    // Redirect happens if user has logged out manually but browses to
    // restricted page.
    if (routeRequiresAuthentication && userIsNotLoggedIn) {
      next({
        name: "login",
        params: { language },
      })
    } else {
      next()
    }
  })

  /**
   * Get page title from currently matched routes
   *
   * @return {String}
   */
  router.getPageTitle = () => getPageTitle(router.currentRoute.matched, findRoute(router.currentRoute, routes))

  /**
   * Get all relevant route names for document views
   * @param {String} materialType Material type
   * @return {Object}
   */
  router.getRouteNames = (materialType) => {
    const materialRoutes = {
      jump: null,
      pdf: null,
      view: null,
    }

    try {
      materialRoutes.jump = router.findMaterialJumpToRoute(materialType)
    // eslint-disable-next-line no-empty
    } catch (err) {}
    try {
      materialRoutes.pdf = router.findMaterialPdfRoute(materialType)
    // eslint-disable-next-line no-empty
    } catch (err) {}
    try {
      materialRoutes.view = router.findMaterialViewRoute(materialType)
    // eslint-disable-next-line no-empty
    } catch (err) {}

    return materialRoutes
  }

  /**
   * Find parent route of current route.
   *
   * @return {Object} Parent route configuration
   */
  router.getParentRoute = () => findParentRoute(router, router.currentRoute)

  /**
   * Find group route (parent of parent) of current route if route is part of a
   * group route. Otherwise return null
   *
   * @param {Object} route Route configuration
   * @return {Object} Group route configuration
   */
  router.getGroupRoute = route => route.isPartOfGroup
    // The group route itself is a wrapper and the first child is the actual
    // route used for routing all group's materials
    ? findParentRoute(router, route).children[0]
    : null

  /**
   * Find material view route for specific document's material so routing can be
   * @param {String} materialType Material type
   * @return {String} Name of the route
   * @throws Exception when no route is found
   */
  router.findMaterialViewRoute = materialType => findRouteEndsWidth(".documentView", materialType, routes)

  /**
   * Find material pdf route for specific document's material
   * @param {String} materialType Material type
   * @return {String} Name of the route
   * @throws Exception when no route is found
   */
  router.findMaterialPdfRoute = materialType => findRouteEndsWidth(".documentPdf", materialType, routes)

  /**
   * Find jumpto document route for specific material
   * @param {String} materialType Material type
   * @return {String} Name of the route
   * @throws Exception when no route is found
   */
  router.findMaterialJumpToRoute = materialType => findRouteEndsWidth(".jumpto", materialType, routes)

  return router
}

export default createRouter
