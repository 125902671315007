import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "insurance-court-decision",
  title: "label.insuranceCourtDecision",
  description: "description.insuranceCourtDecision",
  languages: ["fi"],
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
  }),
}
