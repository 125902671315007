<template lang="html">
  <ContentContainer variant="home">
    <MainContainer>
      <Throbber v-if="loading" />

      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="blocks.length > 0">
        <FrontPage
          :configurations="configurations"
          :blocks="blocks"
          :image-src="imageSrc"
          :options-document-list-item="optionsDocumentListItem"
          :ui-language="uiLanguage"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import { mapActions, mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import { ALL as materials } from "@/config/material-type"
import editaConfig from "@/edita.config.js" // TODO: A better way to pass config than import from module in common code?

export default {
  name: "FrontPageWrapper",
  data() {
    return {
      error: null,
      loading: false,
      blocks: [],
      blocksConfig: editaConfig.FrontPage || {},
      optionsDocumentListItem: editaConfig.DocumentListItem,
    }
  },
  computed: {
    ...mapState("auth", { loggedIn: "loggedIn" }),
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    config() {
      if (this.loggedIn) {
        return this.blocksConfig.auth
      } else {
        return this.blocksConfig.guest
      }
    },
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    imageSrc() {
      return `${this.backendApi}/image`
    },
  },
  watch: {
    "loggedIn": "getData", // Watch for when user logs in; show correct blocks
    "loginError": "getData", // Show error in login form if login fails; blocks has to be updated
    "uiLanguage": "getData", // If user switches language, get appropriate documents
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions("document", { storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS }),
    getData() {
      const getData = this.getFrontpageData

      getData.bind(this)
      getData()
    },
    async getFrontpageData() {
      this.loading = true
      this.error = null

      this.blocks = await Promise.all(this.config.blocks
        ? this.config.blocks
          .filter(block => !block.onlyLanguages || block.onlyLanguages.indexOf(this.uiLanguage) >= 0)
          .map((block) => {
            switch (block.type) {
              case "latest":
                return this.getLatest(block)
                  .then((items) => {
                    // Combine items with block definition
                    return {
                      ...block,
                      items: items,
                    }
                  })
              case "introduction":
                return block
              default:
                return null
            }
          })
        : [])
        .then(blocks => blocks.filter(block => block)) // Filter empty blocks
        .then((blocks) => {
          this.error = null
          this.loading = false

          return blocks
        })
        .catch((error) => {
          this.loading = false
          // TODO: Add this to log somewhere the user can't see.
          // eslint-disable-next-line no-console
          console.error(error)
          this.error = "error.general"

          return []
        })
    },
    getLatest(block) {
      const payload = {
        materialType: block.materialType instanceof Array
          ? block.materialType // TODO: Remove this when all configs use arrays
          : [block.materialType],
        languages: [this.uiLanguage],
        limit: block.count,
        sort: "latestFirst",
      }

      return this.storeSearchDocuments(payload)
        .then(result => result.documents) // We only need documents and not counts
    },
  },
}
</script>
