<script>
import { mapState } from "vuex"
import { ALL as materials } from "@/config/material-type"
import editaConfig from "@/edita.config.js"

export default {
  props: {
    materialType: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    defaultLanguageRoute() {
      return {
        ...this.$router.currentRoute,
        params: { language: editaConfig.languages[0] },
      }
    },
    /**
     * Check if any given materialType (prop) has ui language
     */
    hasMaterialsUiLanguage() {
      const materialTypesWithUiLanguage = this.materialType
        .map(materialType => materials[materialType])
        .filter(materialType => materialType.languages.indexOf(this.uiLanguage) >= 0)

      return materialTypesWithUiLanguage.length > 0
    },
    heading() {
      return this.$route.meta.title
    },
    imageSrc() {
      return `${this.backendApi}/image`
    },
  },
}
</script>
