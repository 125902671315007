<script>
export default {
  data() {
    return { showBacktotop: false }
  },
  mounted() {
    document.getElementById("app").addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    document.getElementById("app").removeEventListener("scroll", this.onScroll)
  },
  methods: {
    goBacktotop() {
      document.querySelector("article.eds-BaseArticle").scrollIntoView({ behavior: "smooth" })
    },
    // eslint-disable-next-line no-unused-vars
    onScroll(evt) {
      const watchElement = document.getElementById("app")

      this.showBacktotop = watchElement.scrollTop > 300
    },
  },
}
</script>
