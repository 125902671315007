<template>
  <div
    v-if="subject"
    :class="[`group-${materialGroup}`]"
  >
    <Heading level="h2">
      {{ $t(label) }}
    </Heading>
    <Throbber
      v-if="loading"
    />
    <FeedbackMessage
      v-if="error"
      variant="error"
    >
      {{ $t(error) }}
    </FeedbackMessage>
    <DocumentList
      v-if="!loading && documentCount > 0"
      :configurations="configurations"
      :documents="documents"
      :image-src="imageSrc"
      :options-document-list-item="optionsDocumentListItem"
      :ui-language="uiLanguage"
      variant="latest"
      view="grid"
    />
    <BaseParagraph v-if="!loading && !error && documentCount === 0">
      {{ $t("error.noDocuments") }}
    </BaseParagraph>
    <BaseLink
      v-if="showMore && documentCount > 0"
      :to="showMoreRoute"
    >
      {{ $t("label.more") }}
    </BaseLink>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import { handleError } from "@wrappers/utils.js"
import { ALL as materials } from "@/config/material-type"

export default {
  name: "SubjectList",
  props: {
    label: {
      type: String,
      required: true,
    },
    materialGroup: {
      type: String,
      required: true,
    },
    materialTypes: {
      type: Array,
      required: true,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentCount: 0,
      documents: [],
      error: null,
      loading: true,
      optionsDocumentListItem: { DocumentActions: { orientation: "vertical" } },
    }
  },
  computed: {
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    imageSrc() {
      return `${this.backendApi}/image`
    },
    showMoreRoute() {
      return {
        name: "search",
        params: { language: this.uiLanguage },
        query: {
          materialGroups: this.materialGroup,
          subjects: this.subject,
          search: true,
        },
      }
    },
  },
  watch: { "subject": "getDocuments" },
  created() {
    this.getDocuments()
  },
  methods: {
    ...mapActions("document", { storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS }),
    getDocuments() {
      const payload = {
        languages: [this.uiLanguage],
        materialType: this.materialTypes,
        subject: [this.subject],
      }

      this.loading = true
      this.error = null
      this.storeSearchDocuments(payload)
        .then((result) => {
          this.documentCount = result.documentCount
          this.documents = result.documents
          this.loading = false
          this.error = null
        })
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status === 401) {
            this.requireLogin = true
          }
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
  },
}
</script>
