<template lang="html">
  <ContentContainer variant="search">
    <AsideContainer v-if="searching">
      <div class="aside">
        <!-- NOTE: FormSearch is in <aside> AND <main> -->
        <FormSearch
          v-model="data"
          @submit="search"
        />
      </div>
    </AsideContainer>
    <MainContainer>
      <Heading
        ref="searchHeading"
        level="h1"
        tabindex="0"
      >
        {{ searching ? $t("heading.searchResult") : $t("label.advancedSearch") }}
      </Heading>

      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
        <FormLogin
          v-if="requireLogin"
          @submit="login"
        />
      </FeedbackMessage>

      <MainContentContainer>
        <div
          v-if="!!searching"
          class="document-list"
        >
          <SearchResultNumber
            v-if="!loading"
            :document-count="documentCount"
          />
          <SearchSort>
            <BaseButton
              :icon="sortBy === 'year' ? sortOrder : 'desc'"
              :state="sortBy === 'year' ? 'active' : null"
              variant="primary"
              size="medium"
              @click="searchToggleYear"
            >
              {{ $t("label.time") }}
            </BaseButton>
            <BaseButton
              :state="sortBy === 'relevance' ? 'active' : null"
              variant="primary"
              size="medium"
              @click="searchToggleRelevance"
            >
              {{ $t("label.relevance") }}
            </BaseButton>
          </SearchSort>
          <ItemsPerPage
            :current-limit="limit"
            @setLimit="setLimit"
          />
          <ListPaged
            :page="page"
            :page-count="pageCount"
            :show-bottom="!loading"
            @go="goPage"
            @goFirst="firstPage"
            @goLast="lastPage"
            @goNext="nextPage"
            @goPrevious="previousPage"
          >
            <Throbber v-if="loading" />
            <DocumentList
              v-if="documents.length > 0"
              :configurations="configurations"
              :documents="documents"
              :image-src="imageSrc"
              :options-document-list-item="optionsDocumentListItem"
              :query-params="$route.query"
              :ui-language="uiLanguage"
              variant="search-results"
              view="list"
            />
          </ListPaged>
        </div>
        <!-- NOTE: FormSearch is in <aside> AND <main> -->
        <FormSearch
          v-if="!searching"
          v-model="data"
          @submit="search"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import { flatMap, uniq } from "lodash"
import { mapActions, mapState } from "vuex"
import { ALL as materials } from "@common/config/material-type" // TODO: Use configuration from module, not global.
import storeTypes from "@common/config/store-types"
import { handleError } from "./utils.js"
import Pagination from "./mixins/Pagination.vue"
import SearchSort from "./mixins/SearchSort.vue"
import editaConfig from "@/edita.config.js"
import materialGroups from "@/config/material-search"

export default {
  name: "SearchPage",
  mixins: [
    Pagination,
    SearchSort,
  ],
  data() {
    return {
      data: {
        allWords: "",
        anyWords: "",
        exactPhrase: "",
        language: "",
        materialGroups: materialGroups,
        noneWords: "",
        subjects: [],
        languages: [],
      },
      documents: [],
      error: null,
      loading: true,
      requireLogin: false,
      searching: false,
      submitted: false,
      optionsDocumentListItem: editaConfig.DocumentListItem,
    }
  },
  computed: {
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    ...mapState("static", ["subjects"]),
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    imageSrc() {
      return `${this.backendApi}/image`
    },
    routeQuery() {
      return {
        allWords: this.data.allWords,
        anyWords: this.data.anyWords,
        exactPhrase: this.data.exactPhrase,
        noneWords: this.data.noneWords,
        materialGroups: this.data.materialGroups
          .filter(group => group.materialTypes && group.selected)
          .map(group => group.id)
          .join(","),
        offset: this.offset,
        limit: this.limit,
        search: true,
        subjects: this.data.subjects
          .filter(subject => subject.selected && subject.id !== "all")
          .map(subject => subject.id)
          .join(","),
        languages: this.data.languages
          .filter(language => language.selected && language.id !== "all_lang")
          .map(language => language.id)
          .join(","),
        sortBy: this.sortBy,
        order: this.sortOrder,
      }
    },
    subjectsForm() {
      return this.subjects
    },
    languagesForFilter() {
      const materialTypes = uniq(flatMap(this.data.materialGroups
        .filter(group => group.materialTypes)
        .map(group => group.materialTypes)
      ))
      const languages = uniq(flatMap(materialTypes.map(materialType => materials[materialType].languages)))

      return languages
    },
  },
  watch: {
    // call again the method if the route changes
    "$route": "queryUpdateSearch",
    "subjects": "subjectsLoaded", // Once subjects are fetched, update them to data
  },
  created() {
    this.resetSearchParams()
    this.queryUpdateSearch()
  },
  methods: {
    ...mapActions("document", { storeSearchDocuments: storeTypes.SEARCH_DOCUMENTS }),
    getResults() {
      this.loading = true
      this.error = null
      this.documents = []
      this.documentCount = 0
      window.scrollTo(0, 0) // Scroll to top when getting new results
      // TODO: Infinite scroll instead of pagination
      // TODO: Logic for text proximity search
      // TODO: Year
      // TODO: Subjects
      const payload = {
        allWords: this.data.allWords,
        anyWords: this.data.anyWords,
        exactPhrase: this.data.exactPhrase,
        language: this.data.language,
        languages: this.data.languages.filter(language => language.id !== "all_lang" && language.selected)
          .map(language => language.id),
        noneWords: this.data.noneWords,
        materialType: flatMap(this.data.materialGroups
          .filter(group => group.selected), group => group.materialTypes
        ).filter(material => material),
        subject: this.data.subjects.filter(subject => subject.selected)
          .map(subject => subject.id),
        sort: this.sortBy !== "year"
          ? ""
          : this.sortOrder !== "asc"
            ? "latestFirst"
            : "oldestFirst",
        offset: Math.max(0, this.page - 1),
        limit: this.limit,
      }

      this.storeSearchDocuments(payload)
        .then((response) => {
          this.documentCount = response.documentCount
          this.documents = response.documents
          this.loading = false
          this.$refs.searchHeading.$el.focus()
        })
        .catch((error) => {
          this.loading = false
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    resetSearchParams() {
      this.data.allWords = ""
      this.data.anyWords = ""
      this.data.exactPhrase = ""
      this.data.materialGroups.forEach(group => materialGroups.find(mGroup => mGroup.id === group.id).selected)
      this.data.noneWords = ""
      this.data.language = this.uiLanguage
      this.data.languages = [
        {
          id: "all_lang",
          label: "label.all",
          childGroups: this.languagesForFilter.map(language => language),
          selected: true,
        },
        ...this.languagesForFilter.map((language) => {
          return {
            id: language,
            label: `label.${language}`,
            selected: true,
          }
        }),
      ]
      this.data.subjects = editaConfig.routes.subjects.enabled && this.subjects[this.uiLanguage] ? [
        {
          id: "all",
          label: "label.all",
          childGroups: this.subjects[this.uiLanguage].map(subject => subject.id),
          selected: true,
        },
        ...this.subjects[this.uiLanguage].map((subject) => {
          return {
            id: subject.id,
            label: subject.label,
            selected: true,
          }
        }),
      ] : []
      this.documentCount = 0
      this.documents = []
      this.page = 1
      this.searching = false
      this.sortBy = "relevance"
      this.sortOrder = "desc"
    },
    queryUpdateSearch() {
      const query = this.$route.query
      const queryMaterialGroups = query.materialGroups
        ? query.materialGroups.split(",")
        : null
      const querySubjects = query.subjects
        ? query.subjects.split(",")
        : null
      const queryLanguages = query.languages
        ? query.languages.split(",")
        : null
      // Filter user input to only allowed material groups and subjects
      const filteredMaterialGroups = queryMaterialGroups
        ? this.data.materialGroups
          .filter(subject => queryMaterialGroups.indexOf(subject.id) >= 0)
          .map(materialGroup => materialGroup.id)
        : this.data.materialGroups.map(group => group.id)
      const filteredSubjects = querySubjects
        ? this.data.subjects
          .filter(subject => querySubjects.indexOf(subject.id) >= 0)
          .map(subject => subject.id)
        : this.data.subjects.map(subject => subject.id)
      const filteredLanguages = queryLanguages
        ? this.data.languages
          .filter(language => queryLanguages.indexOf(language.id) >= 0)
          .map(language => language.id)
        : this.data.languages.map(language => language.id)

      this.data.allWords = query.allWords ? query.allWords : ""
      this.data.anyWords = query.anyWords ? query.anyWords : ""
      this.data.exactPhrase = query.exactPhrase ? query.exactPhrase : ""
      this.data.noneWords = query.noneWords ? query.noneWords : ""
      this.data.materialGroups.forEach((group) => {
        group.selected = filteredMaterialGroups.indexOf(group.id) >= 0 || (typeof group.materialTypes === "undefined"
          // If group has no materials but has child groups, check all of them instead
          && group.childGroups instanceof Array
          && group.childGroups.every(childId => filteredMaterialGroups.indexOf(childId) >= 0)
        )
      })
      this.data.subjects.forEach((subject) => {
        subject.selected = filteredSubjects.indexOf(subject.id) >= 0 || (subject.id === "all"
          && subject.childGroups instanceof Array
          && subject.childGroups.every(childId => filteredSubjects.indexOf(childId) >= 0)
        )
      })
      this.data.languages.forEach((language) => {
        language.selected = filteredLanguages.indexOf(language.id) >= 0 || (language.id === "all_lang"
          && language.childGroups instanceof Array
          && language.childGroups.every(childId => filteredLanguages.indexOf(childId) >= 0)
        )
      })
      this.limit = query.limit && !isNaN(query.limit)
        ? Math.max(1, parseInt(query.limit))
        : 10
      this.page = query.page && !isNaN(query.page)
        ? Math.max(1, parseInt(query.page))
        : 1
      this.sortBy = query.sortBy ? query.sortBy : "relevance"
      this.sortOrder = query.order === "desc" ? "desc" : "asc"
      this.searching = typeof query.search === "string"
        ? query.search === "true" // Sometimes string
        : !!query.search // Sometimes boolean ... magic?
      this.getResults()
    },
    search() {
      if (this.routeUpdate) {
        this.$router.push({ query: this.routeQuery })
      }
    },
    subjectsLoaded() {
      this.resetSearchParams()
      this.queryUpdateSearch()
    },
    setLimit(limit) {
      this.limit = ""
      this.limit = limit
      this.search()
    },
  },
}
</script>
