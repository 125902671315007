import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "committee-report",
  title: "label.committeeReport",
  description: "description.committeeReport",
  languages: ["fi"],
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
  }),
}
