import Vue from "vue"
import Vuex from "vuex"
import TYPES from "@common/config/store-types"
import { findMaterialIndexObject } from "@common/router/utils"
import { formatHeading } from "./vuex-module/utils"
import moduleAuth from "./vuex-module/auth"
import moduleDirectory from "./vuex-module/directory"
import moduleDocument from "./vuex-module/document"
import moduleFeedback from "./vuex-module/feedback"
import moduleKeyword from "./vuex-module/keyword"
import moduleStatic from "./vuex-module/static"
import i18n from "@/i18n" // Use project's dictionary

// Inject global dependency to Vue
Vue.use(Vuex)

function createStore(options) {
  let router = null // TODO: Router is injected here instead of import. This should be fixed

  // eslint-disable-next-line no-undef
  if (typeof process.env.VUE_APP_API !== "string") {
    throw "[createStore] Environment variable 'VUE_APP_API' not defined or not a string"
  }
  if (typeof options.languages !== "object") {
    throw "[createStore] Application 'languages' not defined or not an array"
  }
  if (typeof options.serviceCode !== "string") {
    throw "[createStore] Application 'serviceCode' not defined or not a string"
  }
  if (typeof options.auth !== "object") {
    throw "[createStore] Application 'auth' not defined or not an array"
  }

  const store = new Vuex.Store({
    // eslint-disable-next-line no-undef
    strict: process.env.NODE_ENV !== "production",
    modules: {
      auth: moduleAuth,
      directory: moduleDirectory,
      document: moduleDocument,
      feedback: moduleFeedback,
      keyword: moduleKeyword,
      static: moduleStatic,
    },
    state: {
      backendApi: process.env.VUE_APP_API, // eslint-disable-line no-undef
      language: options.languages[0], // Use first as default
      languages: options.languages,
      searchSuggestGlobal: [],
      serviceCode: options.serviceCode,
    },
    getters: { $router: () => router },
    mutations: {
      // TODO: Should mutations use const like action does?
      setLanguage(state, payload) {
        state.language = payload.language
      },
    },
    actions: {
      [TYPES.JUMP_TO_ANCHOR]({ rootGetters }, { anchor = "" } = {}) {
        const currentRoute = rootGetters.$router.currentRoute

        if (anchor.length === 0) {
          anchor = currentRoute.hash && currentRoute.hash.length > 0
            ? currentRoute.hash.substring(1)
            : null
        }

        if (anchor && anchor.length > 0) {
          const element = window.document.getElementById(anchor)

          if (currentRoute.hash !== `#${anchor}`) {
            // If we were at different hash or did not have hash, let browser
            // handle the scrolling
            rootGetters.$router.push({ hash: anchor })
          } else if (element) {
            // We were already at anchor, but user may have scrolled the page
            // so manually scroll into element (if it exists)
            element.scrollIntoView()
          }
        }
      },
      [TYPES.SET_PAGE_TITLE]({ rootGetters }) {
        window.document.title = rootGetters.$router.getPageTitle()
      },
      [TYPES.SET_LANGUAGE]({ commit, dispatch }, payload) {
        const html = document.documentElement // returns the html tag

        html.setAttribute("lang", payload.language)
        i18n.locale = payload.language
        commit("setLanguage", payload)
        // When language is changed, update language specific headings
        dispatch(TYPES.UPDATE_SIDEBAR_HEADINGS)
      },
      [TYPES.UPDATE_SIDEBAR_HEADINGS]({ dispatch, rootGetters, rootState }) {
        const routes = rootGetters.$router.options.routes

        // Fetch directory headings for specific language and update routes
        // accordingly for top level headings for sidebar navigation.
        return dispatch(`directory/${TYPES.GET_DIRECTORY_HEADINGS}`, {
          language: rootState.language,
          level: 2,
        })
          .then((response) => {
            if (!(response instanceof Array) || response.length === 0) {
              throw new Error(`Got no heading for language ${rootState.language}:`, response)
            }

            response.forEach((headings) => {
              const materialIndexRouteConfig = findMaterialIndexObject(headings.materialType, routes)

              if (materialIndexRouteConfig) {
                const children = headings.children.map(item => formatHeading(item, materialIndexRouteConfig.name))

                Vue.set(materialIndexRouteConfig, "children", children)
                // eslint-disable-next-line no-undef
              } else if (process.env.VUE_APP_ENV !== "production"
                  // eslint-disable-next-line no-undef
                  && process.env.NODE_ENV !== "staging"
              ) {
                // TODO: Can this be replaced by reject(new Error()) which is
                // caught below to avoid unnecessary if statements?
                // eslint-disable-next-line no-console
                console.error("[store:dispatch:update-sidebar-headings] "
                    + `materialIndexRouteConfig for ${headings.materialType} `
                    + "not found in routes!")
              }
            })
          })
          .catch((error) => {
            // eslint-disable-next-line no-undef
            if (error && process.env.VUE_APP_ENV !== "production"
              // eslint-disable-next-line no-undef
              && process.env.NODE_ENV !== "staging"
            ) {
              // eslint-disable-next-line no-console
              console.error("[store:dispatch:update-sidebar-headings]", error)
            }
          })
      },
    },
  })

  // Inject router here from modules' main.js so we can call router functions
  // when necessary
  store.setRouter = r => router = r

  return store
}

export default createStore
