import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "labour-council-statement",
  title: "label.labourCouncilStatement",
  description: "description.labourCouncilStatement",
  languages: ["fi"],
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
  }),
}
