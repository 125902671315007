<script>
import { ALL as materials } from "@/config/material-type"

export default {
  props: {
    materialType: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // materialFilters is empty when only one material is provided (eg. do not show)
      // or it is populated by "all" and then all different materials provided in
      // materialType prop.
      materialFilters: Array.isArray(this.materialType) && this.materialType.length > 1
        ? this.materialType.reduce((acc, materialType) => [
          ...acc,
          {
            id: materialType,
            label: materials[materialType].title,
            materialTypes: [materialType],
            selected: true,
          },
        ], [
          {
            id: "all",
            label: "label.all",
            childGroups: this.materialType,
            selected: true,
          },
        ])
        : [],
    }
  },
  computed: {
    internalMaterialFilter: {
      // `v-model` works by binding a `value` and then
      // listening for "input" events. When layering
      // multiple `v-model` components, you need to emit
      // those "input" events back up the chain.
      get() {
        return this.materialFilters
      },
      set(filters) {
        this.materialFilters = filters
        this.updateMaterialFilter()
      },
    },
  },
  methods: {
    // This is here so it can be overwritten where mixin is used
    updateMaterialFilter() {},
  },
}
</script>
