import Vue from "vue"
import VueI18n from "vue-i18n"

import messages from "@/config/i18n"

// Inject global dependencies to Vue
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: "fi",
  messages,
})

export default i18n
