import TYPES from "@common/config/store-types"
import { feedback as api } from "@common/repository"

const module = {
  namespaced: true,
  actions: {
    [TYPES.SEND_FEEDBACK](context, payload) {
      return api.sendFeedback(payload)
    },
  },
}

export default module
