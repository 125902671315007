import Vue from "vue"
import { keyword as api } from "@common/repository"
import { addRouteNames } from "@common/router/utils"
import TYPES from "@common/config/store-types"
import { addDocumentActions } from "./utils"

const module = {
  namespaced: true,
  state: { letters: {} },
  mutations: {
    [TYPES.SET_KEYWORD_LETTERS](state, payload) {
      const newState = {
        ...state.letters,
        [payload.materialKey]: payload.letters,
      }

      Vue.set(state, "letters", newState)
    },
  },
  actions: {
    [TYPES.GET_KEYWORD_DOCUMENTS]({ rootGetters, rootState }, payload) {
      // TODO: Implement already fetched data into state? Avoid multiple XHR
      // calls for same data
      return api.getDocuments(payload)
        .then((response) => {
          response.documents = response.documents
            .map(document => addDocumentActions(rootGetters.$router, document, rootState.language, true))
            .map(document => addRouteNames(rootGetters.$router, document))

          return response
        })
    },
    [TYPES.GET_KEYWORD_LETTERS]({ commit, state }, payload) {
      const materialKey = payload.materialType.length > 0
        ? payload.materialType.join(";")
        : "all"

      if (state.letters[materialKey]) {
        return state.letters[materialKey]
      }

      return api.getLetters(payload)
        .then((letters) => {
          commit(TYPES.SET_KEYWORD_LETTERS, {
            letters,
            materialKey,
          })

          return letters
        })
    },
    [TYPES.GET_KEYWORDS](context, payload) {
      // TODO: Implement already fetched data into state? Avoid multiple XHR
      // calls for same data
      return api.getKeywords(payload)
    },
    [TYPES.SEARCH_KEYWORDS](context, payload) {
      return api.searchKeywords(payload)
    },
  },
}

export default module
