import Vue from "vue"
import { document as api } from "@common/repository"
import { addRouteNames } from "@common/router/utils"
import TYPES from "@common/config/store-types"
import { addDocumentActions } from "./utils"

const module = {
  namespaced: true,
  state: { years: {} },
  mutations: {
    [TYPES.SET_DOCUMENT_YEARS](state, payload) {
      const newState = state.years

      newState[payload.materialKey] = {
        ...newState[payload.materialKey],
        [payload.languages]: payload.years,
      }

      Vue.set(state, "years", newState)
    },
  },
  actions: {
    [TYPES.GET_DOCUMENT_BY_ID]({ rootGetters, rootState }, payload) {
      return api.getDocumentById(payload)
        .then(document => addDocumentActions(rootGetters.$router, document, rootState.language, false))
        .then(document => addRouteNames(rootGetters.$router, document))
    },
    [TYPES.GET_DOCUMENT_CNAA]({ rootGetters, rootState }, payload) {
      return api.getDocumentCNAA(payload)
        .then(response => response
          .map((item) => {
            return {
              ...item,
              document: addDocumentActions(rootGetters.$router, item.document, rootState.language, true),
            }
          })
          .map((item) => {
            return {
              ...item,
              document: addRouteNames(rootGetters.$router, item.document),
            }
          })
        )
    },
    [TYPES.GET_YEARS]({ commit, state }, payload) {
      const materialKey = payload.materialType.length > 0
        ? payload.materialType.join(";")
        : "all"
      const languages = payload.languages.join(";")

      if (state.years[materialKey] && state.years[materialKey][languages]) {
        return state.years[materialKey][languages]
      }

      return api.getYears(payload)
        .then((years) => {
          commit(TYPES.SET_DOCUMENT_YEARS, {
            languages,
            materialKey,
            years,
          })

          return years
        })
    },
    [TYPES.MATERIAL_JUMP_TO]({ rootGetters }, { materialType, query = null } = {}) {
      // TODO: How to handle multiple materials?
      rootGetters.$router.push({
        name: rootGetters.$router.findMaterialJumpToRoute(materialType[0]),
        query,
      })
    },
    [TYPES.SEARCH_DOCUMENTS]({ rootGetters, rootState }, payload) {
      // Do not filter to just documents, we need total amount of found
      // documents for pagination. So do not add: .then(response => response.documents)
      return api.searchDocuments(payload)
        .then((response) => {
          response.documents = response.documents
            .map(document => addDocumentActions(rootGetters.$router, document, rootState.language, true))
            .map(document => addRouteNames(rootGetters.$router, document))

          return response
        })
    },
  },
}

export default module
