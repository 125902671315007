import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "supreme-administrative-court-precedent",
  title: "label.supremeAdministrativeCourtPrecedent",
  description: "description.supremeAdministrativeCourtPrecedent",
  languages: [
    "fi",
    "sv",
  ],
  configuration: merge({}, defaultConfig, {
    actions: { languages: true },
    listing: {
      caption: true,
      title: true,
      type: true,
    },
  }),
}
