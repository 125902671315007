import axios from "./axios-instance"
import store from "@/store"

export default {
  /**
   * @param Object Payload containing document information
   */
  getDocumentById: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || typeof payload.id !== "string"
    ) {
      reject(new Error("[document.getDocumentById] Payload incorrect"))
    }
    axios(store)({
      url: `/document/${payload.id}`,
      method: "GET",
      params: payload.payload, // Payload from where store function is called
    })
      .then(response => resolve(response.data))
      .catch(reject)
  }),
  getDocumentCNAA: ({ language, materialType }) => new Promise((resolve, reject) => {
    if (!materialType || !language) {
      reject(new Error("[document.getDocumentCNAA] Payload incorrect"))
    }
    axios(store)({
      url: `/document/common-names-and-abbreviations/${materialType}/${language}`,
      method: "GET",
    })
      .then(response => resolve(response.data))
      .catch(reject)
  }),
  /**
   * @param Object Payload containing section information
   */
  getYears: payload => new Promise((resolve, reject) => {
    if (typeof payload !== "object"
      || !(payload.materialType instanceof Array)
      || !(payload.languages instanceof Array)
    ) {
      reject(new Error("[document.getYears] Payload incorrect"))
    }
    axios(store)({
      // url: `/document/years/${payload.materialType}/${payload.language}`,
      url: "/document/years",
      method: "GET",
      params: payload,
    })
      .then(response => resolve(response.data))
      .catch(reject)
  }),
  /**
   * @param Object Payload containing search query params
   */
  searchDocuments: payload => axios(store)({
    url: "/document",
    method: "GET",
    params: payload,
  })
    .then(response => response.data),
  /**
   * @param Object Payload containing document information
   */
  searchTitles: payload => axios(store)({
    url: "/document/titles",
    method: "GET",
    params: payload,
  })
    .then(response => response.data),
}
