<template lang="html">
  <router-view
    v-if="section"
    :configuration="section.props.configuration"
    :material-type="materialType"
    :section="section"
  />
</template>

<script>

export default {
  name: "MaterialPageWrapper",
  props: {
    /**
     * Material type string or array of strings
     */
    materialType: {
      type: Array,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return { section: null }
  },
  watch: { "$route": "setSectionRoute" },
  created() {
    this.setSectionRoute()
  },
  methods: {
    setSectionRoute() {
      let routeConfiguration = this.$router.getParentRoute()

      // Check if material itself should have sidebar; this is usually not the
      // case with materials with only document view. So use groups (eg. case-law)
      // sidebar instead
      if (routeConfiguration.sidebarUseGroupSection) {
        routeConfiguration = this.$router.getGroupRoute(routeConfiguration)
      }
      this.section = routeConfiguration
    },
  },
}
</script>
