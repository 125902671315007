import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"

import ArchiveWrapper from "@wrappers/ArchiveWrapper.vue"
import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: "uutiset",
    children: [
      {
        path: "",
        name: "newsArticle.index",
        component: DocumentListWrapper,
        meta: {
          title: "heading.newsArticle.index",
          isPublic: !!isPublic,
        },
      },
      {
        path: "vuosihakemisto/:year(\\d{4})?",
        name: "newsArticle.archive",
        component: ArchiveWrapper,
        meta: {
          title: "heading.newsArticle.archive",
          isPublic: !!isPublic,
        },
      },
      {
        path: ":documentKey(\\d+)",
        name: "newsArticle.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
        },
        hidden: true, // Do not show in Nav
      },
    ],
    highlightItem: highlight,
  })
}
