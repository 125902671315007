<template lang="html">
  <ContentContainer variant="keyword">
    <MainContainer>
      <Heading level="h1">
        {{ isKeywordList ? $t("label.keywords") : text }}
      </Heading>

      <Throbber v-if="loadingLetters" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="navLetters.length > 0">
        <!-- TODO: Keyword search field -->
        <BaseNav
          :active-params="{ text: letter }"
          :active="$route.name"
          :anchor="$route.hash"
          :items="navLetters"
          :ui-language="uiLanguage"
          variant="letter"
          orientation="horizontal"
        />
        <ListPaged
          :page="page"
          :page-count="pageCount"
          :show-bottom="!loadingContent"
          @go="goPage"
          @goFirst="firstPage"
          @goLast="lastPage"
          @goNext="nextPage"
          @goPrevious="previousPage"
        >
          <Throbber v-if="loadingContent" />
          <KeywordList
            v-if="isKeywordList && !loadingContent && keywords.length > 0"
            :aria-label="$t('label.keywords.byLetter', { letter: letter.toUpperCase() })"
            :keywords="keywords"
            :route-name="$route.name"
            :ui-language="uiLanguage"
          />
          <DocumentList
            v-if="!isKeywordList && !loadingContent && documents.length > 0"
            :configurations="configurations"
            :documents="documents"
            :image-src="imageSrc"
            :options-document-list-item="optionsDocumentListItem"
            :show-keyword="text"
            :ui-language="uiLanguage"
            variant="keywords-listing"
            view="list"
          />
        </ListPaged>

        <BaseParagraph v-if="isKeywordList && !loadingContent && keywords.length === 0">
          {{ $t("error.noKeywords") }}
        </BaseParagraph>
        <BaseParagraph v-if="!isKeywordList && !loadingContent && documents.length === 0">
          {{ $t("error.noDocuments") }}
        </BaseParagraph>
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import { mapActions, mapState } from "vuex"
import storeTypes from "@common/config/store-types"
import { handleError } from "./utils.js"
import Pagination from "./mixins/Pagination.vue"
import { ALL as materials } from "@/config/material-type"
import editaConfig from "@/edita.config.js"

const ALPHABETS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "Å",
  "Ä",
  "Ö",
]

export default {
  name: "KeywordListWrapper",
  mixins: [Pagination],
  props: {
    /**
     * Material type for which to get keywords for
     */
    materialType: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      documents: [],
      error: false,
      keywordCount: 0,
      keywords: [],
      limit: 50, // Override Pagination mixin
      loadingContent: false,
      loadingLetters: true,
      optionsDocumentListItem: editaConfig.DocumentListItem,
    }
  },
  computed: {
    ...mapState("keyword", ["letters"]),
    ...mapState({
      backendApi: "backendApi",
      uiLanguage: "language",
    }),
    configurations() {
      return Object.keys(materials).reduce((accum, materialType) => {
        accum[materialType] = materials[materialType].configuration

        return accum
      }, {})
    },
    imageSrc() {
      return `${this.backendApi}/image`
    },
    isKeywordList() {
      return this.text.length <= 1
    },
    letter() {
      return this.text.substring(0, 1).toLowerCase()
    },
    navLetters() {
      // TODO: Handle multiple material types! This handles only single material type (and not an array)
      const materialType = this.materialType.length > 0 ? this.materialType[0] : "all"

      const letters = ALPHABETS.map((letter) => {
        const apiLetter = typeof this.letters === "object" && this.letters[materialType] instanceof Array
          ? this.letters[materialType].find(aLetter => aLetter.letter === letter)
          : null

        return {
          letter: letter,
          keywords: apiLetter ? apiLetter.keywords : 0,
        }
      })

      return letters.map((item) => {
        return {
          meta: { title: item.letter },
          name: item.keywords ? this.$route.name : null, // No keywords found -> no link
          navParams: {
            // Set what params to use for route link
            text: item.letter.toLowerCase(),
          },
        }
      })
    },
    /* Override Pagination mixin */
    pageCount() {
      return this.isKeywordList
        ? Math.ceil(this.keywordCount / this.limit)
        : Math.ceil(this.documentCount / this.limit)
    },
    text() {
      return this.$route.params.text && this.$route.params.text.length > 0
        ? this.$route.params.text
        : "a" // Default to a to show keywords in index
    },
  },
  watch: {
    "$route.params.text": "changeText", // Text changes -> show always first page
    "materialType": "getLetters", // Material type changes -> get correct keyword letters
  },
  created() {
    this.getLetters()
  },
  methods: {
    ...mapActions("keyword", {
      storeGetDocuments: storeTypes.GET_KEYWORD_DOCUMENTS,
      storeGetLetters: storeTypes.GET_KEYWORD_LETTERS,
      storeGetKeywords: storeTypes.GET_KEYWORDS,
    }),
    changeText() {
      this.keywordCount = 0
      this.keywords = []
      this.documentCount = 0
      this.documents = []
      this.firstPage()
    },
    getLetters() {
      const payload = {
        language: this.uiLanguage,
        materialType: this.materialType,
      }

      this.error = false

      this.storeGetLetters(payload)
        .then(() => {
          this.loadingLetters = false
          this.update() // Get content for keyword letter
        })
        .catch((error) => {
          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    getDocuments(keyword) {
      const payload = {
        language: this.uiLanguage,
        word: keyword,
        materialType: this.materialType,
        limit: this.limit,
        offset: this.page - 1,
      }

      this.error = false
      this.loadingContent = true

      this.storeGetDocuments(payload)
        .then((documents) => {
          this.documentCount = documents.total
          this.documents = documents.documents
          this.loadingContent = false
        })
        .catch((error) => {
          // TODO: How to handle nonexisting keyword?
          // Temporarily disable displayig error message as document list
          // already shows "no documents" so is this enough?
          if (error.response && error.response.status === 404) {
            this.loadingContent = false
          } else {
            handleError(error)
              .then((errorString) => {
                this.error = errorString
                this.loadingContent = false
              })
          }
        })
    },
    getKeywords(letter) {
      const payload = {
        language: this.uiLanguage,
        letter: letter,
        materialType: this.materialType,
        limit: this.limit,
        offset: this.page - 1,
      }

      this.error = false
      this.loadingContent = true

      this.storeGetKeywords(payload)
        .then((keywords) => {
          this.keywordCount = keywords.total
          this.keywords = keywords.items
          this.loadingContent = false
        })
        .catch((error) => {
          handleError(error)
            .then((errorString) => {
              this.error = errorString
              this.loadingContent = false
            })
        })
    },
    update() {
      this.loadingContent = false
      if (this.text.length <= 1) {
        this.getKeywords(this.text)
      } else {
        this.getDocuments(this.text)
      }
    },
    /* Override Pagination mixin */
    updateRoute() {
      this.update()
    },
  },
}
</script>
