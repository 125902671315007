<template lang="html">
  <ContentContainer variant="login">
    <MainContainer>
      <LanguageSelector
        v-if="!loggedIn && applicationLanguages.length > 1"
        :languages="applicationLanguages"
        :ui-language="uiLanguage"
      />
      <Logo
        v-if="!loggedIn"
        :name="serviceName"
        category="service"
      />
      <MainContentContainer>
        <FormLogin
          :errors="loginError ? ['error.unknownUsernameOrPassword'] : []"
          @submit="login"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script>
import { mapActions, mapState } from "vuex"
import languages from "@common/config/languages"
import storeTypes from "@common/config/store-types"
import editaConfig from "@/edita.config.js"

export default {
  name: "LoginWrapper",
  computed: {
    ...mapState({
      availableLanguages: "languages",
      uiLanguage: "language",
    }),
    ...mapState("auth", {
      loggedIn: "loggedIn",
      loginError: "error",
    }),
    applicationLanguages() {
      return languages.filter(language => this.availableLanguages.indexOf(language.code) >= 0)
    },
    serviceName() {
      return editaConfig.serviceCode
    },
  },
  watch: {
    "loggedIn": function(newValue, oldValue) {
      // NOTE: This may cause problems if we want to show login form when user
      // is already logged in. It will redirect to front page.
      if (newValue && !oldValue) {
        this.goLoginRedirect()
      }
    },
  },
  methods: {
    ...mapActions("auth", { goLoginRedirect: storeTypes.GO_LOGIN_REDIRECT }),
    login(data) {
      this.$emit("login", data)
    },
  },
}
</script>
