// TODO: Separate repostory for configurations. Api, Client and DS have material configuration
import ADMINISTRATIVE_COURT_DECISION from "./administrative-court-decision"
import COMMITTEE_REPORT from "./committee-report"
import COURT_OF_APPEAL_DECISION from "./court-of-appeal-decision"
import DEFAULT_CONFIGURATION from "./configuration/default"
import ENVIRONMENT_BULLETIN from "./environment-bulletin"
import EU_STATUTE_CONSOLIDATED from "./eu-statute-consolidated"
import GOVERNMENT_BILL from "./government-bill"
import INSURANCE_COURT_DECISION from "./insurance-court-decision"
import INTERNATIONAL_TREATY_CONSOLIDATED from "./international-treaty-consolidated"
import LABOUR_COUNCIL_STATEMENT from "./labour-council-statement"
import LABOUR_COURT_DECISION from "./labour-court-decision"
import MODULE_OTHER_DOCUMENT from "./module-other-document"
import MODULE_OTHER_DOCUMENT_PDF from "./module-other-document-pdf"
import MODULE_STATUTE_CONSOLIDATED_PDF from "./module-statute-consolidated-pdf"
import NEWS_ARTICLE from "./news-article"
import STATUTE_AMENDMENT from "./statute-amendment"
import STATUTE_CONSOLIDATED from "./statute-consolidated"
import STATUTE_METADATA from "./statute-metadata"
import SUPREME_ADMINISTRATIVE_COURT_PRECEDENT from "./supreme-administrative-court-precedent"
import SUPREME_COURT_PRECEDENT from "./supreme-court-precedent"
import TYOSUHDEASIOIDEN_KASIKIRJA from "./tyosuhdeasioiden-kasikirja"

// Combination of configurations for easier use
const ALL = {
  [ADMINISTRATIVE_COURT_DECISION.materialType]: ADMINISTRATIVE_COURT_DECISION,
  [COMMITTEE_REPORT.materialType]: COMMITTEE_REPORT,
  [COURT_OF_APPEAL_DECISION.materialType]: COURT_OF_APPEAL_DECISION,
  [ENVIRONMENT_BULLETIN.materialType]: ENVIRONMENT_BULLETIN,
  [EU_STATUTE_CONSOLIDATED.materialType]: EU_STATUTE_CONSOLIDATED,
  [GOVERNMENT_BILL.materialType]: GOVERNMENT_BILL,
  [INSURANCE_COURT_DECISION.materialType]: INSURANCE_COURT_DECISION,
  [INTERNATIONAL_TREATY_CONSOLIDATED.materialType]: INTERNATIONAL_TREATY_CONSOLIDATED,
  [LABOUR_COUNCIL_STATEMENT.materialType]: LABOUR_COUNCIL_STATEMENT,
  [LABOUR_COURT_DECISION.materialType]: LABOUR_COURT_DECISION,
  [MODULE_OTHER_DOCUMENT_PDF.materialType]: MODULE_OTHER_DOCUMENT_PDF,
  [MODULE_OTHER_DOCUMENT.materialType]: MODULE_OTHER_DOCUMENT,
  [MODULE_STATUTE_CONSOLIDATED_PDF.materialType]: MODULE_STATUTE_CONSOLIDATED_PDF,
  [NEWS_ARTICLE.materialType]: NEWS_ARTICLE,
  [STATUTE_AMENDMENT.materialType]: STATUTE_AMENDMENT,
  [STATUTE_CONSOLIDATED.materialType]: STATUTE_CONSOLIDATED,
  [STATUTE_METADATA.materialType]: STATUTE_METADATA,
  [SUPREME_ADMINISTRATIVE_COURT_PRECEDENT.materialType]: SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  [SUPREME_COURT_PRECEDENT.materialType]: SUPREME_COURT_PRECEDENT,
  [TYOSUHDEASIOIDEN_KASIKIRJA.materialType]: TYOSUHDEASIOIDEN_KASIKIRJA,
}

export {
  ADMINISTRATIVE_COURT_DECISION,
  ALL,
  COMMITTEE_REPORT,
  COURT_OF_APPEAL_DECISION,
  DEFAULT_CONFIGURATION,
  ENVIRONMENT_BULLETIN,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  INSURANCE_COURT_DECISION,
  INTERNATIONAL_TREATY_CONSOLIDATED,
  LABOUR_COUNCIL_STATEMENT,
  LABOUR_COURT_DECISION,
  MODULE_OTHER_DOCUMENT_PDF,
  MODULE_OTHER_DOCUMENT,
  MODULE_STATUTE_CONSOLIDATED_PDF,
  NEWS_ARTICLE,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
  STATUTE_METADATA,
  SUPREME_ADMINISTRATIVE_COURT_PRECEDENT,
  SUPREME_COURT_PRECEDENT,
  TYOSUHDEASIOIDEN_KASIKIRJA,
}
