<script>
export default {
  data() {
    return {
      sortBy: "relevance",
      sortOrder: "desc",
    }
  },
  methods: {
    search() {}, // Override where it is used in
    searchToggleRelevance() {
      if (this.sortBy !== "relevance") {
        this.sortBy = "relevance"
        this.sortOrder = "desc"
        this.search()
      }
    },
    searchToggleYear() {
      if (this.sortBy !== "year") {
        this.sortBy = "year"
        this.sortOrder = "desc"
      } else {
        this.sortOrder = this.sortOrder === "desc" ? "asc" : "desc"
      }
      this.search()
    },
  },
}
</script>
