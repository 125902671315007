// TODO: This file exists in KorentoClient and EditaDS
// All language configurations available
// Use edita.config.js in project directory to choose what
// languages are used in project
export default [
  // Default language is first
  {
    code: "fi",
    text: "language.finnish",
  },
  {
    code: "sv",
    text: "language.swedish",
  },
  {
    code: "en",
    text: "language.english",
  },
]
