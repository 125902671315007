<template lang="html">
  <ContentContainer variant="section">
    <SidebarMaterial
      :active="$route.name"
      :anchor="$route.hash"
      :advanced-search-route="advancedSearchRoute"
      :has-subsection="configuration.sidebar.subsection.enabled"
      :has-search="configuration.sidebar.search"
      :subsubsection="configuration.sidebar.subsection.subitems"
      :has-update-info="configuration.sidebar.updateInfo"
      :items="section.children"
      :material-type="materialType"
      :options-material-jump-to="configuration.sidebar.jumpToDocument"
      :section="section"
      :ui-language="uiLanguage"
      :update-info="updateInfo"
      @material-jump-to="storeMaterialJumpTo"
      @search-global="searchGlobal"
    />
    <MainContainer>
      <Heading
        v-if="heading"
        level="h1"
      >
        {{ $t(heading) }}
      </Heading>

      <Throbber v-if="loading" />
      <FeedbackMessage
        v-if="error"
        variant="error"
      >
        {{ $t(error) }}
      </FeedbackMessage>

      <MainContentContainer v-if="hasData">
        <DirectoryList
          :anchor="$route.hash"
          :configurations="configurations"
          :directory="data"
          :ui-language="uiLanguage"
          :options-document-list-item="optionsDocumentListItem"
          @jump-to-anchor="jumpToAnchor"
        />
      </MainContentContainer>
    </MainContainer>
  </ContentContainer>
</template>

<script lang="js">
import { isEqual } from "lodash"
import { mapActions } from "vuex"
import storeTypes from "@common/config/store-types"
import { handleError } from "./utils.js"
import DocumentBase from "./mixins/DocumentBase.vue"
import Sidebar from "./mixins/Sidebar.vue"
import editaConfig from "@/edita.config.js"

export default {
  name: "DirectoryListWrapper",
  mixins: [
    DocumentBase,
    Sidebar,
  ],
  props: {
    /**
     * Unused
     */
    configuration: {
      type: Object,
      default() {
        return {}
      },
    },
    /**
     * Material type which's directory is shown
     */
    materialType: {
      type: Array,
      required: true,
    },
    /**
     * Current section configuration
     */
    section: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === "object"
      },
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      requireLogin: false,
      data: { content: [] },
      headings: [],
      optionsDocumentListItem: editaConfig.DocumentListItem,
    }
  },
  computed: {
    hasData() {
      return this.data && this.data.children && this.data.children.length > 0
    },
  },
  watch: {
    // call again the method if the route changes
    "$route.params": function(newParams, oldParams) {
      if (!isEqual(newParams, oldParams)) {
        this.getDirectoryData()
      }
    },
  },
  created() {
    this.getDirectoryData()
  },
  methods: {
    ...mapActions("directory", { storeGetDirectory: storeTypes.GET_DIRECTORY }),
    ...mapActions("document", { storeMaterialJumpTo: storeTypes.MATERIAL_JUMP_TO }),
    getDirectoryData() {
      this.loading = true
      this.error = null
      this.data = null

      const payload = {
        materialType: this.materialType[0],
        language: this.uiLanguage,
      }

      this.storeGetDirectory(payload)
        .then((response) => {
          this.data = Object.freeze(response)
          this.loading = false
        })
        .catch((error) => {
          this.loading = false

          if (error.response && error.response.status === 404) {
            this.requireLogin = true
            if (error.response.data.error === "Directory not found") {
              // TODO: Should not rely in message but use status code
              this.error = "error.noDocumentsDefined"

              return
            }
          }

          handleError(error)
            .then((errorString) => {
              this.error = errorString
            })
        })
    },
    jumpToAnchor(payload) {
      this.$store.dispatch(storeTypes.JUMP_TO_ANCHOR, payload)
    },
  },
}
</script>
