import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "court-of-appeal-decision",
  title: "label.courtOfAppealDecision",
  languages: [
    "fi",
    "sv",
  ],
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
  }),
}
