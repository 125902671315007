export default {
  actions: {
    edilexReferenceCard: false,
    edilexStatuteAmendment: false,
    finlexReferenceCard: false,
    languages: false,
    pdf: false,
    referenceCard: false,
    // Document page only
    bookmark: false, // TODO: Bookmark
    print: true,
    social: false, // TODO: Social media sharing
  },
  listing: {
    documentLimit: 100, // Check Api DocumentController:find() for allowed limits
    caption: false,
    title: false,
    image: false,
    type: false,
    references: false, // If document has references, show them
    // Material is not on this service, link to Edilex instead. Use function if
    // material is not in service. Function will take in document and return URL
    customLink: false, // false | function
  },
  sidebar: {
    enabled: true,
    subsection: {
      enabled: true,
      subitems: {
        enabled: true,
        translate: true,
      },
    },
    updateInfo: false, // This can be true|false|string (material-type)
    jumpToDocument: {
      enabled: false, // Show jumpTo form
      article: false,
      chapter: true,
      number: true,
      section: true,
      year: true,
    },
    jumpToToc: {
      enabled: false,
      article: {
        enabled: false,
        required: false,
      },
      chapter: {
        enabled: false,
        required: false,
      },
      number: {
        enabled: false,
        required: false,
      },
      section: {
        enabled: false,
        required: false,
      },
      year: {
        enabled: false,
        required: false,
      },
    },
    search: false,
  },
  view: { // Document view
    // NOTE: Linking has additional check for UI language.
    // For example when module has only finnish UI but user views swedish
    // document, the keyword/subject is displayed but not linked even when
    // linking is set to true. This is because there is no swedish UI for
    // keyword/subject view.
    keywords: {
      link: true,
      show: true,
    },
  },
}
