export default {
  auth: {
    // Can user log in to service?
    useAuthentication: false,
    // Is authentication required to access pages? If yes, redirect user to login page
    // If guests are allowed in the service, this should be false
    requireAuthentication: false,
    // NOTE: If we want to limit access for guest user, we could use something
    // like below to fine tune it:
    // guest: {
    //   archive: false,
    //   documentView: false,
    //   search: false,
    // },
  },
  languages: ["fi"],
  pageTitle: {
    // Truncate document title for page title after X characters
    documentTitleLength: 128,
    separator: "|",
    // Show index route in page title. This is usually the latest
    // listing. So it would say in page title like
    // "Uusimmat uutiset | Uutiset | Headpower"
    // When false: "Uutiset | Headpower"
    // Other routes (eg. archive) will always show in page title
    showMaterialIndexInTitle: true,
  },
  // Use sidebar to open documents (from document view) into sidebar instead of
  // as main document. This can be overriden by providing data-sidebar-document
  // attribute for <a> link elements in document content.
  useSidebarDocument: false,
  // Common sections that can be added to application routes
  routes: {
    feedback: false,
    keywords: true,
    subjects: {
      autoShowRoutes: [], // Which route names should auto show items. Empty = always
      enabled: false,
      materialGroups: [],
    },
  },
  DocumentListItem: { DocumentActions: { orientation: "vertical" } },
  FrontPage: {
    auth: {
      // eslint-disable-next-line array-bracket-newline
      blocks: [
        // {
        //   type: "latest",
        //   onlyLanguage: ["fi"], // [optional] Do not show this in other languages
        //   materialType: [NEWS_ARTICLE.materialType],
        //   heading: "heading.newsArticle.frontpage",
        //   count: 5,
        //   view: "grid",
        //   orientation: "vertical",
        // },
        // eslint-disable-next-line array-bracket-newline
      ],
    },
    guest: { blocks: [] },
  },
  PageHeader: {
    requireAuthentication: false,
    extraNav: [],
  },
  PageFooter: {
    requireAuthentication: false,
    BrandingOwner: {
      url: "",
      name: "",
    },
    PageFooterLinks: [],
    PageFooterOtherServices: [],
  },
}
