import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "statute-amendment",
  languages: [
    "fi",
    "sv",
  ],
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
      references: true,
    },
  }),
}
