import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "environment-bulletin",
  title: "label.environmentBulletin",
  description: "description.environmentBulletin",
  languages: [
    "fi",
    "sv",
  ],
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
      references: true,
    },
    sidebar: { updateInfo: true },
  }),
}
