import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"

import ArchiveWrapper from "@wrappers/ArchiveWrapper.vue"
import DocumentJumpToWrapper from "@wrappers/DocumentJumpToWrapper.vue"
import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: "he",
    children: [
      {
        path: "",
        name: "governmentBill.index",
        component: DocumentListWrapper,
        meta: {
          title: "heading.governmentBill.index",
          isPublic: !!isPublic,
        },
      },
      {
        path: "siirry",
        name: "governmentBill.jumpto",
        component: DocumentJumpToWrapper,
        meta: {
          title: "heading.governmentBill.jumpto",
          isPublic: !!isPublic,
        },
        hidden: true,
      },
      {
        path: "vuosihakemisto/:year(\\d{4})?",
        name: "governmentBill.archive",
        component: ArchiveWrapper,
        meta: {
          title: "heading.governmentBill.archive",
          isPublic: !!isPublic,
        },
      },
      {
        path: ":documentKey(\\d+)",
        name: "governmentBill.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
        },
        hidden: true, // Do not show in Nav
      },
    ],
    highlightItem: highlight,
  })
}
