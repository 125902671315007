async function handleError(inputError) {
  const error = await inputError

  // eslint-disable-next-line no-undef
  if (error && process.env.VUE_APP_ENV !== "production"
    // eslint-disable-next-line no-undef
    && process.env.NODE_ENV !== "staging"
  ) {
    // eslint-disable-next-line no-console
    console.error(`Error: ${error}`, error.stack)
  }

  if (error.response) {
    return `error.code.${error.response.status}`
  } else {
    return "error.general"
  }
}

export { handleError }
