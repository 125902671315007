import axios from "./axios-instance"
import store from "@/store"

export default {
  sendFeedback: payload => axios(store)({
    url: "/feedback",
    method: "POST",
    data: payload,
  }),
}
