import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"
import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: "kho",
    children: [
      {
        path: "",
        name: "supremeAdministrativeCourtPrecedent.index",
        component: DocumentListWrapper,
        meta: {
          title: "heading.supremeAdministrativeCourtPrecedent.index",
          isPublic: !!isPublic,
        },
      },
      {
        path: ":documentKey(\\d+)/:documentLanguage(en|fi|sv)?",
        name: "supremeAdministrativeCourtPrecedent.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
        },
        hidden: true, // Do not show in Nav
      },
    ],
    highlightItem: highlight,
  })
}
