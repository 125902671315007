import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "supreme-court-precedent",
  title: "label.supremeCourtPrecedent",
  description: "description.supremeCourtPrecedent",
  languages: [
    "fi",
    "sv",
  ],
  configuration: merge({}, defaultConfig, {
    actions: { languages: true },
    listing: {
      caption: true,
      title: true,
      type: true,
      references: true,
    },
  }),
}
