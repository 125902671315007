<script>
import { mapState } from "vuex"
import editaConfig from "@/edita.config.js"

export default {
  computed: {
    ...mapState("static", ["subjects"]),
    subjectNavItems() {
      return this.subjects && this.subjects[this.uiLanguage]
        ? this.subjects[this.uiLanguage].map((subject) => {
          return {
            label: subject.label,
            route: {
              name: "subjects",
              params: {
                language: this.uiLanguage,
                text: subject.label,
              },
            },
          }
        })
        : []
    },
    subjectNavShowItems() {
      // No auto show routes -> show always
      return editaConfig.routes.subjects.autoShowRoutes.length === 0
        || editaConfig.routes.subjects.autoShowRoutes.indexOf(this.$route.name) >= 0
    },
  },
}
</script>
