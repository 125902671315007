import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "news-article",
  languages: ["fi"],
  title: "label.newsArticle",
  description: "description.newsArticle",
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      image: true,
      type: true,
    },
  }),
}
