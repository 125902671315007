import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "module-statute-consolidated-pdf",
  languages: [
    "fi",
    "sv",
  ],
  title: "label.moduleStatuteConsolidated",
  description: "description.moduleStatuteConsolidated",
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
    sidebar: { enabled: false },
  }),
}
