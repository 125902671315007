import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"

import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: "eu-lainsaadanto",
    children: [
      {
        // Use \w for regex to match [A-Za-z0-9_]
        // eslint-disable-next-line max-len,no-useless-escape
        path: ":documentKey(\\w+)/:documentLanguage(en|fi|sv)?",
        name: "euStatuteConsolidated.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
        },
        hidden: true,
        highlightItem: highlight,
      },
      {
        path: "",
        redirect: { name: "not-found" },
      },
    ],
  })
}
