import { directory as api } from "@common/repository"
import { addRouteNames } from "@common/router/utils"
import TYPES from "@common/config/store-types"
import Vue from "vue"
import { addDocumentActions } from "./utils"

function addDocumentRoutes(router, data, uiLanguage) {
  data.children.map((child) => {
    if (child.type === "document" && child.document) {
      child.document = addDocumentActions(router, child.document, uiLanguage, true)
      child.document = addRouteNames(router, child.document)
    } else if (child.type === "node" && child.children) {
      child = addDocumentRoutes(router, child, uiLanguage)
    }

    return child
  })

  return data
}

const module = {
  namespaced: true,
  state: {
    directories: {},
    headings: [],
  },
  mutations: {
    [TYPES.SET_DIRECTORY](state, payload) {
      const newDirectories = state.directories

      newDirectories[payload.materialType] = {
        ...newDirectories[payload.materialType],
        [payload.language]: payload,
      }

      Vue.set(state, "directories", newDirectories)
    },
    [TYPES.SET_DIRECTORY_HEADINGS](state, headings) {
      Vue.set(state, "headings", headings)
    },
  },
  actions: {
    [TYPES.GET_DIRECTORY]({ commit, rootGetters, rootState, state }, payload) {
      if (state.directories[payload.materialType] && state.directories[payload.materialType][payload.language]) {
        return state.directories[payload.materialType][payload.language]
      }

      return api.getDirectory(payload)
        .then(response => addDocumentRoutes(rootGetters.$router, response, rootState.language))
        .then((response) => {
          commit(TYPES.SET_DIRECTORY, response)

          return response
        })
    },
    [TYPES.GET_DIRECTORY_HEADINGS]({ commit, state }, payload) {
      const filteredHeadings = state.headings.filter(item => item.language === payload.language)

      if (filteredHeadings.length > 0) {
        return filteredHeadings
      }

      return api.getHeadings(payload)
        .then((headings) => {
          commit(TYPES.SET_DIRECTORY_HEADINGS, headings)

          return headings.filter(item => item.language === payload.language)
        })
    },
  },
}

export default module
