import STORETYPES from "@common/config/store-types"
import editaConfig from "@/edita.config.js"

function checkUserLogin(store, to, next, needLoginCheck) {
  const toRoute = {
    // Save only necessary information of the route user attempted
    name: to.name,
    params: to.params,
    query: to.query,
  }
  const loginRoute = {
    name: "login",
    params: { language: to.params.language },
  }

  // If user is required to login, go to login page. Otherwise continue route check
  if (needLoginCheck) {
    store.dispatch(`auth/${STORETYPES.SET_LOGIN_REDIRECT}`, toRoute)

    return next(loginRoute)
  } else {
    return next()
  }
}

export default store => (to, from, next) => {
  const toIsPublic = to.meta && !!to.meta.isPublic
  const pageNeedAccess = (editaConfig.auth.requireAuthentication && !toIsPublic) || !toIsPublic

  if (store.state.auth.whoamiDone) {
    return checkUserLogin(store, to, next, pageNeedAccess && !store.state.auth.loggedIn && !to.path.includes("/auth/"))
  }

  return store.dispatch(`auth/${STORETYPES.WHOAMI}`)
    .then(() => next())
    .catch((err) => {
      const requestNeedAccess = err.response.status === 401 || err.response.status === 403

      checkUserLogin(store, to, next, pageNeedAccess && requestNeedAccess)
    })
}
