import { merge } from "lodash"
import defaultConfig from "./configuration/default"

export default {
  materialType: "government-bill",
  languages: ["fi"],
  title: "label.governmentBill",
  description: "description.governmentBill",
  configuration: merge({}, defaultConfig, {
    listing: {
      caption: true,
      title: true,
      type: true,
    },
    sidebar: {
      jumpToDocument: {
        enabled: true,
        chapter: false,
        number: true,
        section: false,
        year: true,
      },
    },
  }),
}
