<script>
export default {
  data() {
    return {
      documentCount: 0,
      limit: 10,
      page: this.$route.query.page ? Math.max(1, parseInt(this.$route.query.page)) : 1,
      routeUpdate: true, // TODO: Allow page shown in route and linking to pages?
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.documentCount / this.limit)
    },
  },
  methods: {
    firstPage() {
      this.page = 1
      this.updateRoute()
    },
    goPage(page) {
      this.page = page
      this.updateRoute()
    },
    lastPage() {
      this.page = this.pageCount
      this.updateRoute()
    },
    nextPage() {
      this.page++
      this.updateRoute()
    },
    previousPage() {
      this.page--
      this.updateRoute()
    },
    updateRoute() {
      if (this.routeUpdate) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: this.page,
          },
        })
      }
    },
  },
}
</script>
